<template>
  <main>
    <div v-if="lot == 1" class="done">
      <img class="mobile lost-mobile" src="/ch5.jpg" />
      <div class="done-left">
        <img class="done-img1" src="/chocolate2.jpg" />
        <img class="bonbons2" src="/bonbons2.png" />
      </div>
      <div class="done-right">
        <img class="done-img2" src="/loose-txt.png" />
      </div>
    </div>

    <div v-if="lot == 2" class="done win">
      <img class="mobile lost-mobile" src="/mobile-bouqet.jpg" />
      <div class="done-left">
        <img class="done-img3" src="/lot-fleurs.png" />
      </div>
      <div class="done-right">
        <img class="done-img2" src="/bravo-fleur-text.png" />
      </div>
    </div>

    <div v-if="lot == 3" class="done win">
      <img class="mobile lost-mobile" src="/mobile-bon.jpg" />
      <div class="done-left">
        <img class="done-img3" src="/lot-fleurs.png" />
      </div>
      <div class="done-right">
        <img class="done-img2" src="/bravo-bon-text.jpg" />
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "win",
  data: function() {
    return {
      lot: undefined,
    };
  },
  mounted() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var forcelot = url.searchParams.get("lot");

 
    if (forcelot) {
      this.lot = forcelot;

    } else {

      if (window.lot <= 0) {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var lot = url.searchParams.get("lot");
        if (lot) this.lot = lot;
      } else {
        this.lot = window.lot;
      }
    }
  },
};
</script>
